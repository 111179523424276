/* @tailwind base; */
/* @tailwind components; */
@tailwind utilities;

html, body, #app {
    height: 100%;
}

a {
    color: #30bc21;
}

a.subtle {
    color: inherit;
    text-decoration: none;
}

body {
    margin: 0;
    padding: 0;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    background: black;
    color: white;
    cursor: default;
    overflow-x: hidden;
}
