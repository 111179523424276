.pointer-events-none {
  pointer-events: none;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.static {
  position: static;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.z-20 {
  z-index: 20;
}

.z-10 {
  z-index: 10;
}

.m-0 {
  margin: 0;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mt-8 {
  margin-top: 2rem;
}

.box-border {
  box-sizing: border-box;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.hidden {
  display: none;
}

.h-full {
  height: 100%;
}

.h-5 {
  height: 1.25rem;
}

.min-h-screen {
  min-height: 100vh;
}

.w-full {
  width: 100%;
}

.shrink-0 {
  flex-shrink: 0;
}

.grow {
  flex-grow: 1;
}

.basis-20 {
  flex-basis: 5rem;
}

.basis-1 {
  flex-basis: .25rem;
}

.snap-x {
  scroll-snap-type: x var(--tw-scroll-snap-strictness);
}

.snap-mandatory {
  --tw-scroll-snap-strictness: mandatory;
}

.snap-end {
  scroll-snap-align: end;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.items-end {
  align-items: flex-end;
}

.items-stretch {
  align-items: stretch;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-scroll {
  overflow: scroll;
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity) );
}

.p-12 {
  padding: 3rem;
}

.p-1 {
  padding: .25rem;
}

.p-0 {
  padding: 0;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.pl-12 {
  padding-left: 3rem;
}

.pr-3 {
  padding-right: .75rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity) );
}

html, body, #app {
  height: 100%;
}

a {
  color: #30bc21;
}

a.subtle {
  color: inherit;
  text-decoration: none;
}

body {
  color: #fff;
  cursor: default;
  background: #000;
  margin: 0;
  padding: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif;
  overflow-x: hidden;
}

/*# sourceMappingURL=index.f8418009.css.map */
